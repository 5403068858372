<template>
  <b-form-row class="form-group">
    <b-col class="col-sm-3 hidden-xs">
      <label></label>
    </b-col>
    <b-col class="col-sm-4">
      <img
        src="../../assets/images/cards/visa.png"
        width="33"
        height="20"
        title="VISA"
      />
      <img
        src="../../assets/images/cards/mastercard.png"
        width="33"
        height="20"
        title="MASTERCARD"
      />
      <img
        src="../../assets/images/cards/amex.png"
        width="33"
        height="20"
        title="AMEX"
      />
      <img
        src="../../assets/images/cards/elo.png"
        width="33"
        height="20"
        title="ELO"
      />
      <img
        src="../../assets/images/cards/discover.png"
        width="33"
        height="20"
        title="DISCOVER"
      />
    </b-col>
  </b-form-row>
</template>

<script>
  export default {};
</script>

<style>
</style>
