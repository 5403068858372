export const storeDetachedService = {
  getCardBrandFromCardNumber,
  getStates,
  getYears,
  getMonths
};

function getCardBrandFromCardNumber (cardNumber) {
  const cardNumberNoSpace = cardNumber.replace(/\s/g, '');
  const amex = new RegExp('^3[47][0-9]{13}$');
  const visa = new RegExp('^4[0-9]{12}(?:[0-9]{3})?$');
  const cup1 = new RegExp('^62[0-9]{14}[0-9]*$');
  const cup2 = new RegExp('^81[0-9]{14}[0-9]*$');
  const mastercard = new RegExp('^5[1-5][0-9]{14}$');
  const mastercard2 = new RegExp('^2[2-7][0-9]{14}$');
  const disco1 = new RegExp('^6011[0-9]{12}[0-9]*$');
  const disco2 = new RegExp('^62[24568][0-9]{13}[0-9]*$');
  const disco3 = new RegExp('^6[45][0-9]{14}[0-9]*$');
  const elo = new RegExp('^((431274)|(438935)|(451416)|(457393)|(504175)|(627780)|(636297)|(636368)|( 40117)[8-9]|(45763)[1-2]|(506)(699|7[1-7][1-8])|(509)[0-9][0-9][ 0-9]|(65003)[1-3]|(6500)(3[5-9]|4[0-9]|5[0-1])|(6504)(0[5-9] |1[0-9]|2[0-9]|3[0-9])|(650)(4(8[5-9]|9[0-9])|5([0-2 ][0-9]|3[0-8]))|(6505)(4[1-9]|[5-8][0-9]|9[0-8])|(6507)( 0[0-9]|1[0-8])|(65072)[0-7]|(6509)(0[1-9]|1[0-9]|20)|(6516)(5 [2-9]|[6-7][0-9])|(6550)[0-1][0-9]|(6550)(2[1-9]|[3-4][0 -9]|5[0-8]))');

  const diners = new RegExp('^3[0689][0-9]{12}[0-9]*$');
  const jcb = new RegExp('^35[0-9]{14}[0-9]*$');

  if (cardNumber === '0000 0000 0000 0001') {
    return 'VISA';
  }

  if (visa.test(cardNumberNoSpace)) {
    return 'VISA';
  }
  if (amex.test(cardNumberNoSpace)) {
    return 'AMEX';
  }
  if (mastercard.test(cardNumberNoSpace) || mastercard2.test(cardNumberNoSpace)) {
    return 'MASTER';
  }
  if (disco1.test(cardNumberNoSpace) || disco2.test(cardNumberNoSpace) || disco3.test(cardNumberNoSpace)) {
    return 'DISCOVER';
  }
  if (diners.test(cardNumberNoSpace)) {
    return 'DINERS';
  }
  if (jcb.test(cardNumberNoSpace)) {
    return 'JCB';
  }
  if (cup1.test(cardNumberNoSpace) || cup2.test(cardNumberNoSpace)) {
    return 'CHINA_UNION_PAY';
  }
  if (elo.test(cardNumberNoSpace)) {
    return 'ELO';
  }
  return undefined;
}

function getStates () {
  return [
    { value: '', text: 'Selecione seu estado' },
    { value: 'AC', text: 'Acre' },
    { value: 'AL', text: 'Alagoas' },
    { value: 'AP', text: 'Amapá' },
    { value: 'AM', text: 'Amazonas' },
    { value: 'BA', text: 'Bahia' },
    { value: 'CE', text: 'Ceará' },
    { value: 'DF', text: 'Distrito Federal' },
    { value: 'ES', text: 'Espírito Santo' },
    { value: 'GO', text: 'Goiás' },
    { value: 'MA', text: 'Maranhão' },
    { value: 'MT', text: 'Mato Grosso' },
    { value: 'MS', text: 'Mato Grosso do Sul' },
    { value: 'MG', text: 'Minas Gerais' },
    { value: 'PR', text: 'Paraná' },
    { value: 'PB', text: 'Paraíba' },
    { value: 'PA', text: 'Pará' },
    { value: 'PE', text: 'Pernambuco' },
    { value: 'PI', text: 'Piauí' },
    { value: 'RJ', text: 'Rio de Janeiro' },
    { value: 'RN', text: 'Rio Grande do Norte' },
    { value: 'RS', text: 'Rio Grande do Sul' },
    { value: 'RO', text: 'Rondônia' },
    { value: 'RR', text: 'Roraima' },
    { value: 'SC', text: 'Santa Catarina' },
    { value: 'SE', text: 'Sergipe' },
    { value: 'SP', text: 'São Paulo' },
    { value: 'TO', text: 'Tocantins' }
  ];
}

function getYears () {
  return [
    { value: '', text: 'Ano' },
    { value: '2021', text: '2021' },
    { value: '2022', text: '2022' },
    { value: '2023', text: '2023' },
    { value: '2024', text: '2024' },
    { value: '2025', text: '2025' },
    { value: '2026', text: '2026' },
    { value: '2027', text: '2027' },
    { value: '2028', text: '2028' },
    { value: '2029', text: '2029' },
    { value: '2030', text: '2030' },
    { value: '2031', text: '2031' }
  ];
}

function getMonths () {
  return [
    { value: '', text: 'Mês' },
    { value: '01', text: '01' },
    { value: '02', text: '02' },
    { value: '03', text: '03' },
    { value: '04', text: '04' },
    { value: '05', text: '05' },
    { value: '06', text: '06' },
    { value: '07', text: '07' },
    { value: '08', text: '08' },
    { value: '09', text: '09' },
    { value: '10', text: '10' },
    { value: '11', text: '11' },
    { value: '12', text: '12' }
  ];
}
