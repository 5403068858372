<template>
  <b-container>
    <b-row>
      <b-col class="col-sm-12">
        <b-form-row class="form-group">
          <b-col class="col-sm-3 control-label payment-label"
            ><label>Número do Cartão:</label></b-col
          >
          <b-col class="col-sm-4">
            <b-form-input :readonly="readonly"
              v-mask="'#### #### #### ####'"
              v-model="card.number"
              :class="status($v.card.number)"
            >
            </b-form-input>
          </b-col>
        </b-form-row>
        <b-form-row class="form-group">
          <b-col class="col-sm-3 control-label payment-label">
            <label>Bandeira: </label>
          </b-col>
          <b-col class="col-sm-4">
            <b-form-input
              class="not-allowed"
              v-model="card.brand"
              :class="status($v.card.brand)"
              disabled
            >
            </b-form-input>
          </b-col>
        </b-form-row>
        <CardBrandImages></CardBrandImages>
        <b-form-row class="form-group">
          <b-col class="col-sm-3 control-label payment-label">
            <label>Validade: </label>
          </b-col>
          <b-col class="col-sm-2">
            <b-form-select :disabled="readonly"
              v-model="card.expirationMonth"
              :class="status($v.card.expirationMonth)"
              :options="months"
            >
            </b-form-select>
          </b-col>
          <b-col class="col-sm-2">
            <b-form-select :disabled="readonly"
              v-model="card.expirationYear"
              :class="status($v.card.expirationYear)"
              :options="years"
            >
            </b-form-select>
          </b-col>
        </b-form-row>
        <b-form-row class="form-group">
          <b-col class="col-sm-3 control-label payment-label"
            ><label>Código de Segurança: </label></b-col
          >
          <b-col class="col-sm-2">
            <b-form-input :readonly="readonly"
              v-mask="card.securityCodeMask"
              v-model="card.securityCode"
              :class="status($v.card.securityCode)"
            >
            </b-form-input>
          </b-col>
        </b-form-row>
        <b-form-row class="form-group">
          <b-col class="col-sm-3 control-label payment-label"
            ><label>Nome do Titular: </label></b-col
          >
          <b-col class="col-sm-5">
            <b-form-input :readonly="readonly"
              v-model="card.holderName"
              :class="status($v.card.holderName)" @keypress="IsText"
            ></b-form-input>
          </b-col>
        </b-form-row>
      </b-col>
    </b-row>
    <div class="col-sm-12 bottom-bar" v-show="!readonly">
      <ul class="list-inline float-right">
        <li class="list-inline-item">
          <b-button class="button-back-style" @click="backToUserFormTab()"
            >Voltar</b-button
          >
        </li>
        <li class="list-inline-item">
          <b-button
            class="button-style"
            @click="handlePaymentFormData()"
            >Avançar</b-button
          >
        </li>
      </ul>
    </div>
  </b-container>
</template>

<script>
  import CardBrandImages from '../CardBrandsImages.vue';
  import { storeDetachedService } from '../../../services/storeDetached.service';
  import { required, minLength, maxLength } from 'vuelidate/lib/validators';
  export default {
    props: { readonly: { value: false, type: Boolean }, load: { type: Object } },
    components: {
      CardBrandImages
    },
    data () {
      return {
        card: {
          number: '',
          brand: '',
          expirationMonth: '',
          expirationYear: '',
          securityCode: '',
          holderName: '',
          securityCodeMask: '###'
        },
        years: [],
        months: []
      };
    },
    validations: {
      card: {
        number: {
          required,
          minLength: minLength(18),
          maxLength: maxLength(19)
        },
        brand: {
          required
        },
        expirationMonth: {
          required
        },
        expirationYear: {
          required
        },
        securityCode: {
          required,
          minLength: minLength(3)
        },
        holderName: {
          required,
          minLength: minLength(3)
        }
      }
    },
    methods: {
      paymentInfo () {
        return this.card;
      },
      loadPaymentInfo (payment) {
        this.card = payment;
      },
      status (validation) {
        return {
          error: validation.$error || validation.error,
          dirty: validation.$dirty
        };
      },
      IsText (event) {
        // eslint-disable-next-line no-useless-escape
        const specials = /[*|\":<>[\]{}`\\()';@&#%/,!.$\d]/;
        if (specials.test(event.key) && event.key !== '.') return event.preventDefault();
      },
      backToUserFormTab () {
        const backToUserFormTabData = { userFormTab: true };
        this.$emit('backToUserFormTab', backToUserFormTabData);
      },
      handlePaymentFormData () {
        this.$v.$touch();
        if (this.$v.$invalid) {
          return false;
        } else {
          const paymentData = {
            card: this.card,
            nextTab: true
          };
          this.$emit('handlePaymentFormData', paymentData);
        }
      }
    },
    watch: {
      'card.number' (newValue) {
        this.card.brand = storeDetachedService.getCardBrandFromCardNumber(newValue);
        if (this.card.brand === 'AMEX' || this.card.brand === 'DISCOVER') {
          this.card.securityCodeMask = '####';
        } else {
          this.card.securityCodeMask = '###';
        }
      }
    },
    async mounted () {
      this.months = storeDetachedService.getMonths();
      this.years = storeDetachedService.getYears();
    }
  };
</script>

<style lang='scss' scoped>
.form-group {
  margin-bottom: 3px !important;
}

.control-label {
  font-size: 12px !important;
}

.button-back-style {
  /* Rectangle 3926 */
  height: 36px;
  width: 154px;
  left: 1049px;
  top: 618px;
  color: #207bdd;
  border-radius: 4px;
  background: #FFFFFF;
  border: 1px solid #207BDD;
  box-sizing: border-box;
  border-radius: 4px;

}

.button-style {
  height: 36px;
  width: 154px;
  left: 1049px;
  top: 618px;
  border-radius: 4px;
  background: #207BDD;
}

label {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #6278A3;
}

.payment-label {
  padding-left: 100px;
}

.not-allowed {
  cursor: not-allowed !important;
}

.error {
  border-color: red;
  background: #fdd;
}

.error:focus {
  outline-color: #f99;
}

.bottom-bar {
  margin-top: 20px;
}
</style>
