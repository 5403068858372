<template>
  <b-container>
    <b-row>
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
      ></loading>
      <b-col class="col-sm-6">
        <b-form-row class="form-group" v-show="!readonly">
          <b-col class="col-sm-3 control-label"><label>* Tipo: </label></b-col>
          <b-col class="col-sm-9">
            <label>
              <input type="radio" value="Pessoa Física" v-model="user.type" />
              Pessoa Física
            </label>
            <label>
              <input
                type="radio"
                id="cnpj-radio-button"
                value="Pessoa Jurídica"
                v-model="user.type"
              />
              Pessoa Jurídica
            </label>
          </b-col>
        </b-form-row>
        <b-form-row
          class="form-group"
          :class="{ 'form-group--error': $v.user.$error }"
        >
          <b-col class="col-sm-3 control-label">
            <label v-if="user.type === 'Pessoa Física'">*CPF:</label>
            <label v-else>*CNPJ: </label>
          </b-col>
          <b-col class="col-sm-6">
            <b-form-input
              :readonly="readonly"
              v-mask="dynamicMask"
              v-model="$v.user.personalId.$model"
              :class="status($v.user.personalId)"
            >
            </b-form-input>
          </b-col>
        </b-form-row>
        <b-form-row class="form-group">
          <b-col class="col-sm-3 control-label">
            <label>*Nome: </label>
          </b-col>
          <b-col class="col-sm-9">
            <b-form-input :readonly="readonly"
              v-model="$v.user.name.$model"
              :class="status($v.user.name)" @keypress="IsText"
            >
            </b-form-input>
          </b-col>
        </b-form-row>
        <b-form-row class="form-group">
          <b-col class="col-sm-3 control-label"
            ><label>*Telefone: </label></b-col
          >
          <b-col class="col-sm-6">
            <b-form-input :readonly="readonly"
              v-mask="'(##) #########'"
              v-model="$v.user.phone.$model"
              :class="status($v.user.phone)"
            >
            </b-form-input>
          </b-col>
        </b-form-row>
        <b-form-row class="form-group">
          <b-col class="col-sm-3 control-label"><label>*E-mail: </label></b-col>
          <b-col class="col-sm-9">
            <b-form-input :readonly="readonly"
              v-model="$v.user.email.$model"
              :class="status($v.user.email)"
            ></b-form-input>
          </b-col>
        </b-form-row>
        <b-form-row class="form-group">
          <b-col class="col-sm-3 control-label"><label>*CEP:</label> </b-col>
          <b-col class="col-sm-4">
            <b-form-input :readonly="readonly"
              v-mask="'#####-###'"
              v-model="$v.user.zipCode.$model"
              :class="status($v.user.zipCode)"
            >
            </b-form-input>
          </b-col>
        </b-form-row>
      </b-col>
      <b-col class="col-sm-6">
        <b-form-row
          class="form-group"
          :class="{ 'form-group--error': $v.user.$error }"
        >
          <b-col class="col-sm-3 control-label"
            ><label>*Logradouro:</label></b-col
          >
          <b-col class="col-sm-9">
            <b-form-input :readonly="readonly"
              v-model="$v.user.address.$model"
              :class="status($v.user.address)"
            ></b-form-input>
          </b-col>
        </b-form-row>
        <b-form-row class="form-group">
          <b-col class="col-sm-3 control-label"><label>*Número: </label></b-col>
          <b-col class="col-sm-3">
            <b-form-input :readonly="readonly"
              v-model="$v.user.number.$model"
              :class="status($v.user.number)" @keypress="IsNumber"
            >
            </b-form-input>
          </b-col>
        </b-form-row>
        <b-form-row class="form-group">
          <b-col class="col-sm-3 control-label"
            ><label>Complemento:</label></b-col
          >
          <b-col class="col-sm-9"
            ><b-form-input :readonly="readonly" v-model="user.complement"> </b-form-input>
          </b-col>
        </b-form-row>
        <b-form-row class="form-group">
          <b-col class="col-sm-3 control-label"><label>*Bairro:</label></b-col>
          <b-col class="col-sm-7">
            <b-form-input :readonly="readonly"
              v-model="user.district"
              :class="status($v.user.district)"
            >
            </b-form-input>
          </b-col>
        </b-form-row>
        <b-form-row class="form-group">
          <b-col class="col-sm-3 control-label"><label>*Estado: </label></b-col>
          <b-col class="col-sm-7">
            <b-select :disabled="readonly" style="min-width: 285px"
              class="selectpicker form-control"
              v-model="user.state"
              :class="status($v.user.state)"
              :options="states"
            >
            </b-select>
          </b-col>
        </b-form-row>
        <b-form-row class="form-group">
          <b-col class="col-sm-3 control-label"> <label>*Cidade: </label></b-col>
          <b-col class="col-sm-7">
            <b-select :disabled="readonly" style="min-width: 285px"
              class="selectpicker form-control"
              v-model="user.city"
              :options="cities"
              :class="status($v.user.city)"
            >
            </b-select>
          </b-col>
        </b-form-row>
      </b-col>
    </b-row>
    <div class="col-sm-12 bottom-bar" v-show="!readonly">
      <ul class="list-inline float-right">
        <li class="list-inline-item">
          <b-button class="button-back-style" @click="backToInitialTab()"
            >Voltar</b-button
          >
        </li>
        <li class="list-inline-item">
          <b-button class="button-style" @click="handleUserFormData()"
            >Avançar</b-button
          >
        </li>
      </ul>
    </div>
  </b-container>
</template>

<script>
  import { required, minLength, maxLength, email } from 'vuelidate/lib/validators';
  import { utilsService } from '../../../services/utils.service';
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';

  export default {
    props: { readonly: { value: false, type: Boolean }, load: { type: Object } },
    components: {
      Loading
    },
    data () {
      return {
        dynamicMask: '###.###.###-##',
        isLoading: false,
        fullPage: true,
        wrongId: false,
        wrongCPF: false,
        user: {
          name: '',
          type: 'Pessoa Física',
          personalId: '',
          phone: '',
          zipCode: '',
          address: '',
          complement: '',
          number: '',
          district: '',
          email: '',
          state: '',
          city: ''
        },
        states: [],
        cities: []
      };
    },
    validations: {
      user: {
        name: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(254)
        },
        personalId: {
          required,
          minLength: minLength(14)
        },
        phone: {
          required,
          minLength: minLength(13),
          maxLength: maxLength(14)
        },
        email: {
          required,
          maxLength: maxLength(254),
          email
        },
        zipCode: {
          required,
          minLength: minLength(8)
        },
        address: {
          maxLength: maxLength(254),
          required
        },
        number: {
          maxLength: maxLength(254),
          required
        },
        district: {
          maxLength: maxLength(254),
          required
        },
        city: {
          required
        },
        state: {
          required
        }
      }
    },
    methods: {
      userInfo () {
        return this.user;
      },
      loadUserInfo (user) {
        this.user = user;
      },
      status (validation) {
        return {
          error: validation.$error || validation.error,
          dirty: validation.$dirty
        };
      },
      IsNumber (event) {
        if (!/\d/.test(event.key) && event.key !== '.') return event.preventDefault();
      },
      IsText (event) {
        // eslint-disable-next-line no-useless-escape
        const specials = /[*|\":<>[\]{}`\\()';@&#%/,!.$\d]/;
        if (specials.test(event.key) && event.key !== '.') return event.preventDefault();
      },
      notifyErrorInZipCodeSearching () {
        this.wrongCPF = true;
        this.$v.user.zipCode.error = true;
        this.$toast.open({ message: 'CEP inexistente!', type: 'error' });
        const setZipCodeToAddressParams = {
          address: '',
          district: '',
          city: '',
          state: '',
          loading: false
        };
        this.setZipCodeDataToAddress(setZipCodeToAddressParams);
      },
      fillAddressFields (addressResult) {
        this.wrongCPF = false;
        this.$v.user.zipCode.error = false;
        const { logradouro, bairro, localidade, uf } = addressResult;
        const setZipCodeToAddressParams = {
          address: logradouro,
          district: bairro,
          city: localidade,
          state: uf,
          loading: false
        };
        this.setZipCodeDataToAddress(setZipCodeToAddressParams);
      },
      setZipCodeDataToAddress (params) {
        const { address, district, city, state, loading } = params;
        this.user.address = address;
        this.user.district = district;
        this.user.city = city;
        this.user.state = state;
        this.isLoading = loading;
      },
      backToInitialTab () {
        const backToInitialTabData = { initialTab: true };
        this.$emit('backToInitialTab', backToInitialTabData);
      },
      handleUserFormData () {
        this.$v.$touch();
        if (this.$v.$invalid || this.wrongId || this.wrongCPF) {
          return false;
        } else {
          const userFormData = {
            user: this.user,
            nextTab: true
          };
          this.$emit('handleUserFormData', userFormData);
        }
      },
      async validatingCNPJInput (cnpj) {
        this.isLoading = true;
        const response = await utilsService.validateCNPJ(cnpj.replace(/\D+/g, ''));
        response.status !== 'ERROR' ? this.fillCNPJFields(response) : this.notifyErrorInCNPJ();
      },
      fillCNPJFields (response) {
        this.user.name = response.nome;
        this.user.zipCode = response.cep.replace(/\./g, '');
        this.user.phone = this.formatingPhoneFromCnpjApiResponse(response.telefone);
        this.isLoading = false;
        this.$v.user.personalId.error = false;
        this.$v.user.personalId.invalid = false;
      },
      notifyErrorInCNPJ () {
        this.isLoading = false;
        this.$v.user.personalId.error = true;
        this.$toast.open({ message: 'CNPJ inválido!', type: 'error' });
      },
      formatingPhoneFromCnpjApiResponse (phone) {
        if (phone.split('/')) {
          const phoneItem = phone.split('/')[0];
          return phoneItem.replace(/[^\d]/g, '');
        }
        return phone;
      }
    },
    watch: {
      'user.type' (newValue) {
        newValue === 'Pessoa Física'
          ? (this.dynamicMask = '###.###.###-##')
          : (this.dynamicMask = '##.###.###/####-##');
      },
      async 'user.zipCode' (newValue) {
        if (newValue.length !== 9) {
        } else if (newValue.length === 9) {
          this.isLoading = true;
          const addressResult = await utilsService.getAddressFromCep(newValue);
          addressResult.erro === true
            ? this.notifyErrorInZipCodeSearching()
            : this.fillAddressFields(addressResult);
        }
      },
      async 'user.state' (newValue) {
        this.user.city = '';
        this.cities = await utilsService.citiesByState(newValue);
      },
      async 'user.personalId' (newValue) {
        if (this.user.type === 'Pessoa Jurídica' && newValue.length === 18) {
          await this.validatingCNPJInput(newValue);
        } else if (this.user.type === 'Pessoa Física' && newValue.length === 14) {
          const response = await utilsService.validateCPF(newValue);
          if (!response) {
            this.isLoading = true;
            this.$v.user.personalId.error = true;
            this.$v.user.personalId.invalid = true;

            this.$toast.open({ message: 'CPF inválido!', type: 'error' });
            this.wrongId = true; // FIX-ME PLS!!!!
            this.isLoading = false;
          } else {
            this.$v.user.personalId.error = false;
            this.$v.user.personalId.invalid = false;
            this.wrongId = false;
          }
        } else if (this.wrongId && this.user.type === 'Pessoa Física' && newValue.length < 14) {
          this.$v.user.personalId.error = false;
          this.$v.user.personalId.invalid = false;
        } else if (this.user.type === 'Pessoa Jurídica' && newValue.length < 18) {
          this.$v.user.personalId.error = true;
          this.$v.user.personalId.invalid = true;
        }
      }
    },
    async mounted () {
      this.states = await utilsService.states();
    }
  };
</script>

<style lang='scss' scoped>

.form-group {
  margin-bottom: 3px !important;
}

.control-label {
  font-size: 12px !important;
}

.button-style {
  height: 36px;
  width: 154px;
  left: 1049px;
  top: 618px;
  border-radius: 4px;
  background: #207BDD;
  border: none;
}

.button-back-style {
  /* Rectangle 3926 */
  height: 36px;
  width: 154px;
  left: 1049px;
  top: 618px;
  color: #207bdd;
  border-radius: 4px;
  background: #FFFFFF;
  border: 1px solid #207BDD;
  box-sizing: border-box;
  border-radius: 4px;

}

label {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #6278A3;
}

#cnpj-radio-button {
  margin-left: 10px;
}

.error {
  border-color: red;
  background: #fdd;
}

.error:focus {
  outline-color: #f99;
}

.bottom-bar {
  margin-top: 20px;
}
</style>
